import React, { memo } from "react";
import Image from "/components/CustomImage"
import styles from "./Products.module.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "react-multi-carousel/lib/styles.css";
import CategoryCarousel from "./Carousel";
import Breadcrumbs from "./Breadcrumbs";
import Filters from "./Filters";
import dynamic from "next/dynamic";
import useCategoryHook from './useCategoryHook';
import SortedProducts from './SortedProducts';
import CategorySkeleton from "./CategorySkeleton";
import Button from "../Button";
import { useRouter } from "next/router";
import RenderWhen from "./RenderWhen";
import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";
import GiftCard from "./GiftCard";
import Categorydescription from "./CategoryDescription";
import BlogPLP from "./BlogPLP";
import HomepageSection from "../Home/HomepageSection";
import CategoryIcon from "../category/CategoryIcon";
const CategoryNoBanner = dynamic(() => import('./CategoryNoBanner'));
const AnniversaryPromotionExtra = dynamic(() => import('/components/AnniversaryTimer'));
const FiltersDialog = dynamic(() => import('../Filters/FiltersDialog'));

const AnniversaryPromotionExtraMemoized = memo(AnniversaryPromotionExtra)
const BreadcrumbsMemozied = memo(Breadcrumbs);

export const Products = ({
  categoryid,
  categoryname,
  categorydescription,
  categoryshortdescription,
  image,
  image_mob,
  blogIdsArray,
  slug,
  url_key,
  productdata,
  img_desktop,
  img_mobile,
  cookie,
  isSsrMobile,
  prefixUrl,
  breadCrumbUrl,
  newCategoryLayout,
  categoryIconEnable = 0,
  categoryIcon = "[]",
  cms_data
}) => {
  const router = useRouter();

  const {
    ItemCategory3,
    session,
    loading,
    handleChange,
    handleFetchMore,
    path,
    bIsFiltersOpen,
    sortvalue,
    selectwidth,
    cancelFilters,
    FiltersApplied,
    appliedFilters,
    initialload,
    handlerApplyFilters,
    bIsFiltersApplied,
    belowmenuconsern,
    head1,
    head2,
    handlerCloseFilters,
    state,
    toggleDrawer,
    config,
    belowmenucategory,
    prodVisibilityArr,
    setProdVisibilityArr,
    page,
    storeid,
    products,
    aggregations,
    aggregationsshow,
    reset,
    isMobileType
  } = useCategoryHook({
    slug,
    productdata,
    url_key,
    categoryid,
    categoryname,
    cookie,
    isSsrMobile,
    router
  });

  // This will run when we apply filter or load more products
  if ((!productdata) || (!initialload && loading)) {
    return (
      <>
        <div className={`${styles.category_product_sec1} ${styles.category_product_sec12}`}>
          <div className="category-banner">
            {!isMobileType ? (
              (Array.isArray(img_desktop) && img_desktop?.length > 1) ? (
                <CategoryCarousel>
                  {
                    img_desktop?.map((item, key) => (
                      <RenderWhen>
                        <RenderWhen.If isTrue={item?.includes(".mp4")}>
                          <video key={key} width="2800" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                            <source src={item} type="video/mp4" />
                          </video>
                        </RenderWhen.If>
                        <RenderWhen.If isTrue>
                          <Image key={key} priority src={item} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} layout="responsive" />
                        </RenderWhen.If>
                      </RenderWhen>

                    ))
                  }
                </CategoryCarousel>
              ) : (
                (Array.isArray(img_desktop) && img_desktop.length > 0) ?
                  <RenderWhen>
                    <RenderWhen.If isTrue={img_desktop?.[0] && img_desktop?.[0].includes(".mp4")}>
                      <video width="2800" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                        <source src={img_desktop[0]} type="video/mp4" data-wf-ignore="true" />
                      </video>
                    </RenderWhen.If>
                    <RenderWhen.If isTrue={img_desktop?.[0] != null}>
                      <Image priority src={img_desktop[0]} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} />
                    </RenderWhen.If>
                  </RenderWhen>
                  : (image) ?
                    <Image priority src={image} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} /> : ''
              )) : (
              (Array.isArray(img_mobile) && img_mobile.length > 1) ? (
                <CategoryCarousel>
                  {
                    img_mobile?.map((item, key) => (
                      <RenderWhen>
                        <RenderWhen.If isTrue={item?.includes(".mp4")}>
                          <video key={key} width="600" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                            <source src={item} type="video/mp4" data-wf-ignore="true" />
                          </video>
                        </RenderWhen.If>
                        <RenderWhen.If isTrue>
                          <Image key={key} priority src={item} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={600} height={400} />
                        </RenderWhen.If>
                      </RenderWhen>
                    ))
                  }
                </CategoryCarousel>
              ) :
                ((Array.isArray(img_mobile) && img_mobile.length > 0) ?
                  <RenderWhen>
                    <RenderWhen.If isTrue={img_mobile?.[0] && img_mobile[0]?.includes(".mp4")}>
                      <video width="600" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                        <source src={img_mobile[0]} type="video/mp4" data-wf-ignore="true" />
                      </video>
                    </RenderWhen.If>
                    <RenderWhen.If isTrue={img_mobile?.[0] != null}>
                      <Image priority placeholder="blur" layout="responsive" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" src={img_mobile[0]} width={600} height={400} />
                    </RenderWhen.If>
                  </RenderWhen>
                  : (image_mob) ? <Image priority placeholder="blur" layout="responsive" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" src={`${path}${image_mob}`} width={600} height={400} /> : ''
                )
            )
            }
          </div>
        </div >

        <Breadcrumbs styles={styles} image={image} isMobileDevice={isMobileType} categoryshortdescription={categoryshortdescription} prefixUrl={breadCrumbUrl} />
        <CategorySkeleton styles={styles} />
      </>
    );
  }

  return (
    <section className="plp_sec1">
      {/* category_no_banner */}
      {
        router.asPath.includes(path) ?
          <CategoryNoBanner
            title='Clearance Sale'
            styles={styles}
          /> : null
      }

      <div className={styles.anniversary_sec12_mergin}></div>

      {
        url_key == config.anniversary_promotion8_extra && config.anniversary_timerdatetime_timer_end_time && new Date(config.anniversary_timerdatetime_timer_end_time) > new Date() &&
        <AnniversaryPromotionExtraMemoized
          duration={config.anniversary_timerdatetime_timer_end_time}
          timerMsg={config.anniversary_timerdatetime_catalog_msg}
          styles={styles}
          websiteID={storeid}
        />
      }

      {/* Top Banner */}
      <div className={`${styles.category_product_sec1} ${styles.category_product_sec12}`}>
        <div className="category-banner">
          {!isMobileType ? (
            (Array.isArray(img_desktop) && img_desktop?.length > 1) ? (
              <CategoryCarousel>
                {
                  img_desktop?.map((item, key) => (
                    <RenderWhen>
                      <RenderWhen.If isTrue={item?.includes(".mp4")}>
                        <video key={key} width="2800" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                          <source src={item} type="video/mp4" />
                        </video>
                      </RenderWhen.If>
                      <RenderWhen.If isTrue>
                        {/* <Image key={key} priority={key == 0 ? true : false} src={item} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} layout="responsive" /> */}
                        <img className={styles.plpBanner} loading={key == 0 ? 'eager' : 'lazy'}
                          src={cloudflareLoader({
                            src: item,
                            width: 1900,
                            quality: 85,
                          })}
                          alt="kama ayurveda banner" />
                      </RenderWhen.If>
                    </RenderWhen>

                  ))
                }
              </CategoryCarousel>
            ) : (
              (Array.isArray(img_desktop) && img_desktop.length > 0) ?
                <RenderWhen>
                  <RenderWhen.If isTrue={img_desktop?.[0] && img_desktop?.[0].includes(".mp4")}>
                    <video width="2800" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                      <source src={img_desktop[0]} type="video/mp4" data-wf-ignore="true" />
                    </video>
                  </RenderWhen.If>
                  <RenderWhen.If isTrue={img_desktop?.[0] != null}>
                    {/* <Image priority src={img_desktop[0]} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} /> */}
                    <img className={styles.plpBanner} loading='eager'
                      src={cloudflareLoader({
                        src: img_desktop?.[0],
                        width: 1900,
                        quality: 85,
                      })}
                      alt="kama ayurveda banner" />
                  </RenderWhen.If>
                </RenderWhen>
                : (image) ?
                  // <Image priority src={image} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={1400} height={400} /> : ''
                  <img className={styles.plpBanner} loading='eager'
                    src={cloudflareLoader({
                      src: image,
                      width: 1900,
                      quality: 85,
                    })}
                    alt="kama ayurveda banner" /> : null
            )) : (
            (Array.isArray(img_mobile) && img_mobile.length > 1) ? (
              <CategoryCarousel>
                {
                  img_mobile?.map((item, key) => (
                    <RenderWhen>
                      <RenderWhen.If isTrue={item?.includes(".mp4")}>
                        <video key={key} width="600" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                          <source src={item} type="video/mp4" data-wf-ignore="true" />
                        </video>
                      </RenderWhen.If>
                      <RenderWhen.If isTrue>
                        {/* <Image key={key} priority src={item} layout="responsive" placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" width={600} height={400} /> */}
                        <img className={styles.plpBanner} loading={key == 0 ? 'eager' : 'lazy'}
                          src={cloudflareLoader({
                            src: item,
                            width: 900,
                            quality: 85,
                          })}
                          alt="kama ayurveda banner" />
                      </RenderWhen.If>
                    </RenderWhen>
                  ))
                }
              </CategoryCarousel>
            ) :
              ((Array.isArray(img_mobile) && img_mobile.length > 0) ?
                <RenderWhen>
                  <RenderWhen.If isTrue={img_mobile?.[0] && img_mobile[0]?.includes(".mp4")}>
                    <video width="600" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                      <source src={img_mobile[0]} type="video/mp4" data-wf-ignore="true" />
                    </video>
                  </RenderWhen.If>
                  <RenderWhen.If isTrue={img_mobile?.[0] != null}>
                    {/* <Image priority placeholder="blur" layout="responsive" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" src={img_mobile[0]} width={600} height={400} /> */}
                    <img className={styles.plpBanner} loading='eager'
                      src={cloudflareLoader({
                        src: img_mobile?.[0],
                        width: 900,
                        quality: 85,
                      })}
                      alt="kama ayurveda banner" />
                  </RenderWhen.If>
                </RenderWhen>
                : (image_mob) ?
                  //  <Image priority placeholder="blur" layout="responsive" blurDataURL="/images/kama-placeholder-kama.jpg" alt="plp banner" src={`${path}${image_mob}`} width={600} height={400} /> : ''
                  <img className={styles.plpBanner} loading='eager'
                    src={cloudflareLoader({
                      src: `${path}${image_mob}`,
                      width: 900,
                      quality: 85,
                    })}
                    alt="kama ayurveda banner" /> : null
              )
          )
          }
        </div>
      </div>

      <BreadcrumbsMemozied
        styles={styles}
        image={image}
        isMobileDevice={isMobileType}
        categoryshortdescription={categoryshortdescription}
        prefixUrl={breadCrumbUrl}
      />

      <div className="container">

        {categoryIconEnable ? <CategoryIcon iconArr={categoryIcon} page='plp' isMobileType={isMobileType} /> : null}

        {!newCategoryLayout ? <Filters
          styles={styles}
          sortValue={sortvalue}
          cancelFilters={cancelFilters}
          aggregationsshow={aggregationsshow}
          selectwidth={selectwidth}
          handleChange={handleChange}
          FiltersApplied={FiltersApplied}
          bIsFiltersApplied={bIsFiltersApplied}
          toggleDrawer={toggleDrawer}
        /> : null}

        <React.Fragment>
          {!newCategoryLayout ? <SortedProducts
            sortvalue={sortvalue}
            products={products}
            storeid={storeid}
            categoryname={categoryname}
            prodVisibilityArr={prodVisibilityArr}
            setProdVisibilityArr={setProdVisibilityArr}
            categoryid={categoryid}
            ItemCategory3={ItemCategory3}
            session={session}
            url_key={url_key}
            config={config} /> : (
            <HomepageSection identifier='holi-offer' cmsData={cms_data} />
          )}
        </React.Fragment>

        {!newCategoryLayout && page?.current_page !== page?.total_pages ? (
          <Button type="button" onClick={handleFetchMore} disabled={loading}>
            {loading ? "Loading..." : "Load More"}
          </Button>
        ) : null}

        {!newCategoryLayout ? <SwipeableDrawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <FiltersDialog
            state={bIsFiltersOpen}
            categoryId={categoryid}
            agg={aggregations}
            onDismiss={handlerCloseFilters}
            onApplyFilters={handlerApplyFilters}
            reset={reset}
            appliedFilters={appliedFilters}
          />
        </SwipeableDrawer> : null}

        {!newCategoryLayout ? <GiftCard
          styles={styles}
          belowmenucategory={belowmenucategory}
          head2={head2}
          websiteurl={prefixUrl}
          belowmenuconsern={belowmenuconsern}
          head1={head1}
        /> : null}

        {!newCategoryLayout ? <Categorydescription
          styles={styles}
          categorydescription={categorydescription}
        /> : null}

        <div className={styles.wrap_categories_blog}>
          <BlogPLP
            isMobileDevice={isMobileType}
            blogIdsArray={blogIdsArray}
            url_key={url_key}
            websiteurl={prefixUrl}
          />
        </div>
      </div>
    </section >
  );
};

