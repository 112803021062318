import React, { useEffect, useState } from 'react';
import ListItem from '../product/list-item';
import SaleListItem from '../product/list-item-sale';

const CategoryProducts = ({ sortvalue, products, storeid, categoryname, prodVisibilityArr, setProdVisibilityArr, categoryid, ItemCategory3, session, url_key, config }) => {
    const [sortedProducts, setSortedProducts] = useState([])

    useEffect(() => {
        if (sortvalue === 'asc') {
            setSortedProducts(products
                .slice()
                .sort((a, b) => a.price.regularPrice.amount.value > b.price.regularPrice.amount.value ? 1 : -1)
                .sort((a, b) => a.stock_status.localeCompare(b.stock_status)))
        } else if (sortvalue === 'desc') {
            setSortedProducts(products
                .slice()
                .sort((a, b) => a.price.regularPrice.amount.value < b.price.regularPrice.amount.value ? 1 : -1)
                .sort((a, b) => a.stock_status.localeCompare(b.stock_status)))
        } else {
            setSortedProducts(products.slice().sort((a, b) => a.stock_status.localeCompare(b.stock_status)))
        }
    }, [sortvalue, products, url_key])

    return (
        url_key == config.anniversary_promotion8_extra ?
            <div className="row">
                {products
                    .slice()
                    .sort((a, b) => sortvalue == 'asc' ? (a.price.regularPrice.amount.value > (b.price.regularPrice.amount.value) ? 1 : -1) : sortvalue == 'desc' ? (a.price.regularPrice.amount.value < (b.price.regularPrice.amount.value) ? 1 : -1) : '')
                    .sort((a, b) => a.stock_status.localeCompare(b.stock_status))
                    .map((product, index) => (
                        <SaleListItem
                            key={product.id}
                            storeid={storeid}
                            id={product.id}
                            name={product.name}
                            sku={product.sku}
                            description={product.short_description.html}
                            slug={product.slug}
                            image={product.small_image.url}
                            url_path={product.url_key}
                            url_key={product.url_key}
                            price={product.price.regularPrice.amount.value}
                            mprice={product.price_range.minimum_price.regular_price.value}
                            type={product.type_id}
                            conf={product.configurable_options}
                            variants={product.variants}
                            stock={product.stock_status}
                            salableqty={product.salableqty.label}
                            media_gallery={product.media_gallery}
                            rating_summary={product.rating_summary}
                            review_count={product.review_count}
                            product_label={product.product_label || product?.variants?.[0]?.product?.product_label}
                            primary_category={product?.primary_category || product?.variants?.[0]?.product?.primary_category}
                            pwa_oneliner={product.pwa_oneliner}
                            session={session}
                            categories={product?.categories}
                            item_list_index={index}
                            item_list_name={categoryname}
                            item_list_id={categoryid}
                            index={index}
                            prodVisibilityArr={prodVisibilityArr}
                            setProdVisibilityArr={setProdVisibilityArr}
                            ItemCategory3={ItemCategory3}
                            size={product?.size}
                        />
                    ))}
            </div> :
            <div className="row">
                {sortedProducts.map((product, index) => (
                    <ListItem
                        key={product.id}
                        index={index}
                        storeid={storeid}
                        id={product.id}
                        name={product.name}
                        sku={product.sku}
                        description={product.short_description.html}
                        slug={product.slug}
                        image={product.small_image.url}
                        url_path={product.url_key}
                        url_key={product.url_key}
                        price={product.price.regularPrice.amount.value}
                        mprice={product.price_range.minimum_price.regular_price.value}
                        type={product.type_id}
                        conf={product.configurable_options}
                        variants={product.variants}
                        stock={product.stock_status}
                        salableqty={product.salableqty.label}
                        media_gallery={product.media_gallery}
                        rating_summary={product.rating_summary}
                        review_count={product.review_count}
                        product_label={product.product_label || product?.variants?.[0]?.product?.product_label}
                        primary_category={product?.primary_category || product?.variants?.[0]?.product?.primary_category}
                        pwa_oneliner={product.pwa_oneliner}
                        categories={product?.categories}
                        product={product}
                        categoryName={categoryname}
                        // isProductImpressionSent={isProductImpressionSent}
                        sortvalue={sortvalue}
                        prodVisibilityArr={prodVisibilityArr}
                        setProdVisibilityArr={setProdVisibilityArr}
                        item_list_index={index}
                        item_list_name={categoryname}
                        item_list_id={categoryid}
                        ItemCategory3={ItemCategory3}
                        size={product?.size}
                    />
                ))}

            </div>
    );
};

export default CategoryProducts;
