import { useEffect } from "react"

export const loadLively = () => {
    useEffect(() => {
        try {
          const script1 = document.createElement('script');
          script1.type = 'text/javascript';
          script1.async = true;
          script1.src = 'https://feed.lively.li/shoppableFeedsV3.min.js';
          document.head.appendChild(script1);

          const script2 = document.createElement('script');
          script2.id = 'livelyAnalytics';
          script2.setAttribute('data-company-id', '7a94329b88');
          script2.type = 'text/javascript';
          script2.async = true;
          script2.src = 'https://pixel-atc.lively.li/pixel.js';
          document.head.appendChild(script2);
      
          return () => {
            try {
              if(document.head.contains(script1)){
                  document.head.removeChild(script1);   
              }
              if(document.head.contains(script2)){
                  document.head.removeChild(script2);
              }
            } catch (error) {
                console.log('Error while removing script lively pdp', error);                        
            }
          };
        } catch (error) {
          console.log('Error while appending script lively pdp', error);
        }
    },[])
}